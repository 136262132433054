import { useContext } from "react";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";

export const PropertyTable = ({ defaultWidth }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { properties } = store;

  const thStyle = { textAlign: "left", paddingLeft: 10 };

  const clickRow = (item) => {
    dispatch({ type: actionTypes.SET_PROPERTY_ROW, payload: item });
  };

  const rowStyle = { cursor: "pointer" };

  return properties ? (
    <table>
      <thead>
        <tr>
          <th style={thStyle}>Name</th>
          <th style={thStyle}>Address</th>
          <th style={thStyle}>Availability Colour</th>
        </tr>
      </thead>
      <tbody>
        {properties
          .sort((a, b) => {
            return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
          })
          .map((item, i) => {
            return (
              <tr key={i} onClick={() => clickRow(item)} style={rowStyle}>
                <td style={{ width: `${defaultWidth.id}px`, paddingLeft: 10 }}>
                  {item.id}
                </td>
                <td
                  style={{
                    width: `${defaultWidth.address}px`,
                    paddingLeft: 10,
                  }}
                >
                  {item.address}
                </td>
                <td
                  style={{
                    width: `${defaultWidth.colour}px`,
                    paddingLeft: 10,
                    backgroundColor: item.colour,
                  }}
                ></td>
              </tr>
            );
          })}
      </tbody>
    </table>
  ) : null;
};
