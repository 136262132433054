import React, { useContext } from "react";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";

export const SelectProperty = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { properties, selectedProperty } = store;

  const selectStyle = { width: 600, marginTop: 10, zIndex: 999 };

  return properties ? (
    <div style={selectStyle}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Property</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedProperty}
          label="Property"
          onChange={(e) => {
            dispatch({
              type: actionTypes.SET_SELECTED_PROPERTY,
              payload: e.target.value,
            });
          }}
        >
          {properties.map((item, i) => {
            return (
              <MenuItem key={i} value={item.id}>
                {item.id}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  ) : null;
};
