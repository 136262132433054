import React from "react";
import { Schedule } from "./bookings/Schedule";
import { SelectProperty } from "./bookings/SelectProperty";

export const Bookings = () => {
  return (
    <div style={{margin:10}}>
      <SelectProperty />
      <Schedule />
    </div>
  );
};
