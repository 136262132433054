import { actionTypes } from "./storeTypes";

export const init_global = {
  auth: undefined,
  bookingsLoaded: false,
  credentials: null,
  customers: null,
  customerRow: null,
  forgotPassword: { status: false, user: "" },
  loggedIn: { status: false, message: "" },
  properties: null,
  propertyRow: null,
  requireNewPassword: false,
  selected: "Properties",
  selectedProperty: "",
  status: {},
};

export function global_reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_AUTH: {
      return {
        ...state,
        auth: action.payload,
      };
    }
    case actionTypes.SET_BOOKINGS_LOADED: {
      return {
        ...state,
        bookingsLoaded: action.payload,
      };
    }
    case actionTypes.SET_CREDENTIALS: {
      return {
        ...state,
        credentials: action.payload,
      };
    }
    case actionTypes.SET_CUSTOMERS: {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case actionTypes.SET_CUSTOMER_ROW: {
      return {
        ...state,
        customerRow: action.payload,
      };
    }
    case actionTypes.SET_FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPassword: action.payload,
      };
    }
    case actionTypes.SET_LOGGED_IN: {
      return {
        ...state,
        loggedIn: action.payload,
      };
    }
    case actionTypes.SET_PROPERTIES: {
      return {
        ...state,
        properties: action.payload,
      };
    }
    case actionTypes.SET_PROPERTY_ROW: {
      return {
        ...state,
        propertyRow: action.payload,
      };
    }
    case actionTypes.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case actionTypes.SET_REQUIRE_NEW_PASSWORD: {
      return {
        ...state,
        requireNewPassword: action.payload,
      };
    }
    case actionTypes.SET_SELECTED: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_PROPERTY: {
      return {
        ...state,
        selectedProperty: action.payload,
      };
    }
    default:
      return state;
  }
}
