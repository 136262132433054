import { useState, useEffect, useContext } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { StoreContext } from "../store/Store";
import { GlobalRefContext } from "../store/GlobalRefs";
import { CustomEditor } from "./availability/CustomEditor";

export const Availability = () => {
  const [store] = useContext(StoreContext);
  const { bookingsLoaded, properties } = store;
  const grefs = useContext(GlobalRefContext);
  const [events, setEvents] = useState();

  useEffect(() => {
    if (properties && bookingsLoaded) {
      const propertyColours = properties.reduce((a, b) => {
        return { ...a, [b.id]: b.colour };
      }, {});
      let events = [];
      grefs.bookings.current.forEach((item) => {
        item.bookings.forEach((booking) => {
          events.push({
            ...booking,
            color: propertyColours[item.id],
            start: new Date(booking.start),
            end: new Date(booking.end),
            event_id: item.id + booking.event_id,
            subtitle: item.id,
            deletable: false,
            editable: false,
            draggable:false
          });
        });
      });
      setEvents(events);
    }
  }, [bookingsLoaded, grefs, properties]);

  return events ? (
    <Scheduler
      events={events}
      height={400}
      week={{
        weekDays: [0, 1, 2, 3, 4, 5],
        weekStartOn: 6,
        startHour: 9,
        endHour: 18,
        step: 60,
        navigation: true,
        disableGoToDay: false,
      }}
      customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
    />
  ) : null;
};
