import React, { useRef, createContext } from "react";
export const GlobalRefContext = createContext(undefined);

export const GlobalRefProvider = ({ children }) => {
  const bookings = useRef([]);

  const refs = {
    bookings,
  };

  return refs ? (
    <GlobalRefContext.Provider value={refs}>
      {children}
    </GlobalRefContext.Provider>
  ) : null;
};
