import { useContext } from "react";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { editDynamoDBData, deleteDynamoDBData } from "../../api/api";
import { validateEmail } from "../utils";

const containerStyle = {
  width: "100%",
};
const parentStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "start",
  gap: 10,
};

const editInputStyle = {
  display: "flex",
  justifyContent: "start",
  marginTop: 20,
  marginBottom: 20,
};

const buttonStyle = {
  border: "1px solid black",
  borderRadius: 8,
  padding: 6,
  cursor: "pointer",
};

export const EditRow = ({ defaultWidth }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { auth, credentials, customerRow, customers } = store;

  const onChange = (e, key) => {
    const newRow = { ...customerRow, [key]: e.target.value };
    dispatch({ type: actionTypes.SET_CUSTOMER_ROW, payload: newRow });
  };

  const validateRow = (key, newRow) => {
    if (
      customerRow &&
      key in customerRow &&
      (customerRow[key] || customerRow[key] === 0)
    ) {
      if (key === "id") {
        console.log("customerRow[key]", customerRow[key]);
        if (!validateEmail(customerRow[key])) {
          alert("Invalid Email");
          return false;
        }
      }
      newRow[key] = customerRow[key];
      return true;
    } else {
      alert(`${key} is mandatory`);
      return false;
    }
  };
  const onSave = () => {
    const newRow = { user: credentials.user };
    if (!validateRow("id", newRow)) return;
    if (!validateRow("firstName", newRow)) return;
    if (!validateRow("surname", newRow)) return;

    //add to customers data
    const newCustomers = customers.filter((item) => !(item.id === newRow.id));
    newCustomers.push(newRow);
    dispatch({
      type: actionTypes.SET_CUSTOMERS,
      payload: newCustomers,
    });
    // SET_CUSTOMERS
    const strNewRow = Object.entries(newRow).reduce((a, b) => {
      return { ...a, [b[0]]: b[1].toString() };
    }, {});
    const asyncfunction = async () => {
      await editDynamoDBData(auth, "customer", strNewRow);
    };
    asyncfunction();
  };

  const onClear = () => {
    dispatch({ type: actionTypes.SET_CUSTOMER_ROW, payload: null });
  };
  const onDelete = () => {
    if (customerRow && "id" in customerRow && customerRow.id) {
      if (window.confirm(`Delete ${customerRow.id}`)) {
        const newCustomers = customers.filter(
          (item) => !(item.id === customerRow.id)
        );
        dispatch({
          type: actionTypes.SET_CUSTOMERS,
          payload: newCustomers,
        });

        dispatch({ type: actionTypes.SET_CUSTOMER_ROW, payload: null });
        const asyncfunction = async () => {
          await deleteDynamoDBData(
            auth,
            "customer",
            credentials.user,
            customerRow.id
          );
        };
        asyncfunction();
      }
    } else {
      alert(`enter a customer to delete`);
    }
  };

  return defaultWidth ? (
    <div style={containerStyle}>
      <div style={parentStyle}>
        <div style={buttonStyle} onClick={() => onClear()}>
          Clear
        </div>
        <div style={buttonStyle} onClick={() => onSave()}>
          Save
        </div>
        <div style={buttonStyle} onClick={() => onDelete()}>
          Delete
        </div>
      </div>
      <div style={editInputStyle}>
        <input
          style={{ width: `${defaultWidth.id}px`, paddingLeft: 10 }}
          value={customerRow ? customerRow.id : ""}
          type="text"
          onChange={(e) => onChange(e, "id")}
        ></input>
        <input
          style={{ width: `${defaultWidth.firstName}px`, paddingLeft: 10 }}
          value={customerRow ? customerRow.firstName : ""}
          type="text"
          onChange={(e) => onChange(e, "firstName")}
        ></input>
        <input
          style={{ width: `${defaultWidth.surname}px`, paddingLeft: 10 }}
          value={customerRow ? customerRow.surname : ""}
          type="text"
          onChange={(e) => onChange(e, "surname")}
        ></input>
      </div>
    </div>
  ) : null;
};
