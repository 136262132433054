import { useContext } from "react";
import { MuiColorInput } from "mui-color-input";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { editDynamoDBData, deleteDynamoDBData } from "../../api/api";

const containerStyle = {
  width: "100%",
  //   padding: 20,
  //   marginLeft: -20,
};
const parentStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "start",
  gap: 10,
};

const editInputStyle = {
  display: "flex",
  justifyContent: "start",
  marginTop: 20,
  marginBottom: 20,
};

const buttonStyle = {
  border: "1px solid black",
  borderRadius: 8,
  padding: 6,
  cursor: "pointer",
};

export const EditRow = ({ defaultWidth }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { auth, credentials, propertyRow, properties } = store;

  const onChange = (val, key) => {
    const newRow = { ...propertyRow, [key]: val };
    dispatch({ type: actionTypes.SET_PROPERTY_ROW, payload: newRow });
  };

  const validateRow = (key, newRow) => {
    if (
      propertyRow &&
      key in propertyRow &&
      (propertyRow[key] || propertyRow[key] === 0)
    ) {
      newRow[key] = propertyRow[key];
      return true;
    } else {
      alert(`${key} is mandatory`);
      return false;
    }
  };
  const onSave = () => {
    const newRow = { user: credentials.user };
    if (!validateRow("id", newRow)) return;
    if (!validateRow("address", newRow)) return;
    if (!validateRow("colour", newRow)) return;

    //add to properties data
    const newProperties = properties.filter((item) => !(item.id === newRow.id));
    newProperties.push(newRow);
    dispatch({
      type: actionTypes.SET_PROPERTIES,
      payload: newProperties,
    });
    // SET_PROPERTIES
    const strNewRow = Object.entries(newRow).reduce((a, b) => {
      return { ...a, [b[0]]: b[1].toString() };
    }, {});
    const asyncfunction = async () => {
      await editDynamoDBData(auth, "property", strNewRow);
    };
    asyncfunction();
  };

  const onClear = () => {
    dispatch({ type: actionTypes.SET_PROPERTY_ROW, payload: null });
  };
  const onDelete = () => {
    if (propertyRow && "id" in propertyRow && propertyRow.id) {
      if (window.confirm(`Delete ${propertyRow.id}`)) {
        const newProperties = properties.filter(
          (item) => !(item.id === propertyRow.id)
        );
        dispatch({
          type: actionTypes.SET_PROPERTIES,
          payload: newProperties,
        });

        dispatch({ type: actionTypes.SET_PROPERTY_ROW, payload: null });
        const asyncfunction = async () => {
          await deleteDynamoDBData(
            auth,
            "property",
            credentials.user,
            propertyRow.id
          );
        };
        asyncfunction();
      }
    } else {
      alert(`enter a property to delete`);
    }
  };

  return defaultWidth ? (
    <div style={containerStyle}>
      <div style={parentStyle}>
        <div style={buttonStyle} onClick={() => onClear()}>
          Clear
        </div>
        <div style={buttonStyle} onClick={() => onSave()}>
          Save
        </div>
        <div style={buttonStyle} onClick={() => onDelete()}>
          Delete
        </div>
      </div>
      <div style={editInputStyle}>
        <input
          style={{ width: `${defaultWidth.id}px`, paddingLeft: 10 }}
          value={propertyRow ? propertyRow.id : ""}
          type="text"
          onChange={(e) => onChange(e.target.value, "id")}
        ></input>
        <input
          style={{ width: `${defaultWidth.address}px`, paddingLeft: 10 }}
          value={propertyRow ? propertyRow.address : ""}
          type="text"
          onChange={(e) => onChange(e.target.value, "address")}
        ></input>
        <MuiColorInput
          format="hex"
          value={
            propertyRow && propertyRow.colour ? propertyRow.colour : "#ffffff"
          }
          onChange={(val) => onChange(val, "colour")}
        />
      </div>
    </div>
  ) : null;
};
