import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";

const App = () => {
  return (
    <div
      style={{
        fontFamily: "Graphic",
        height: "100vh",
        width: "100vw",
        boxSizing: "border-box",
      }}
    >
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </div>
  );
};
const RoutedApp = () => (
  <Router>
    <App></App>
  </Router>
);

export default RoutedApp;
