const apiurl = "https://zq0zxcyfl6.execute-api.us-east-1.amazonaws.com/prod";

export const getDynamoDBData = async (auth, endpoint, user, id = "") => {
  const url = `${apiurl}/${endpoint}?user=${user}&id=${id}`;

  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export async function editDynamoDBData(auth, endpoint, payload) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/${endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export const deleteDynamoDBData = async (auth, endpoint, user, id) => {
  const url = `${apiurl}/${endpoint}?user=${user}&id=${id}`;

  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "Delete",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};
