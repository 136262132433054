export const actionTypes = {
  SET_AUTH: "SET_AUTH",
  SET_BOOKINGS_LOADED: "SET_BOOKINGS_LOADED",
  SET_CREDENTIALS: "SET_CREDENTIALS",
  SET_CUSTOMERS: "SET_CUSTOMERS",
  SET_CUSTOMER_ROW: "SET_CUSTOMER_ROW",
  SET_FORGOT_PASSWORD: "SET_FORGOT_PASSWORD",
  SET_LOGGED_IN: "SET_LOGGED_IN",
  SET_PROPERTIES_FILTERED: "SET_PROPERTIES_FILTERED",
  SET_PROPERTIES: "SET_PROPERTIES",
  SET_PROPERTY_ROW: "SET_PROPERTY_ROW",
  SET_SELECTED: "SET_SELECTED",
  SET_SELECTED_PROPERTY: "SET_SELECTED_PROPERTY",
  SET_STATUS: "SET_STATUS",
  SET_REQUIRE_NEW_PASSWORD: "SET_REQUIRE_NEW_PASSWORD",
};
