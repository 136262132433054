import { useState } from "react";
import dayjs from "dayjs";
import { TextField, Button, DialogActions } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const CustomEditor = ({ scheduler }) => {
  const event = scheduler.edited;

  // Make your own form/state
  const [state] = useState({
    title: event?.title || "",
    description: event?.description || "",
    start: dayjs(event ? event?.start : scheduler.state.start.value),
    end: dayjs(event ? event?.end : scheduler.state.end.value),
    property: event?.subtitle || "",
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="customeEditor" style={{ padding: "1rem" }}>
        <h1>Booking</h1>
        <div style={{ marginTop: 10 }}>
          <TextField label="property" value={state.property} fullWidth />
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField label="customer" value={state.title} fullWidth />
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField label="email" value={state.description} fullWidth />
        </div>
        <div style={{ display: "flex", marginTop: 10, gap: 20 }}>
          <DateTimePicker
            label="Start"
            value={state.start}
            // onChange={(newValue) => handleChange(newValue, "start")}
          />
          <DateTimePicker
            label="end"
            value={state.end}
            // onChange={(newValue) => handleChange(newValue, "end")}
          />
        </div>
      </div>
      <DialogActions>
        <Button onClick={scheduler.close}>Ok</Button>
      </DialogActions>
    </LocalizationProvider>
  );
};
