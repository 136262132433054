import { useState, useEffect, useContext } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { CustomEditor } from "./CustomEditor";
// import { EVENTS } from "./Events";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { editDynamoDBData } from "../../api/api";

export const Schedule = () => {
  const [store] = useContext(StoreContext);
  const { auth, bookingsLoaded, credentials, selectedProperty } = store;
  const [reload, setReload] = useState(false);
  const grefs = useContext(GlobalRefContext);

  useEffect(() => {
    if (selectedProperty) {
      setReload(true);
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  const fetchRemote = async (query) => {
    return new Promise((res) => {
      if (grefs.bookings.current && grefs.bookings.current.length > 0) {
        const properyEvents = grefs.bookings.current.find(
          (item) => item.id === selectedProperty
        );
        const events = properyEvents
          ? properyEvents.bookings.map((item) => {
              return {
                ...item,
                start: new Date(item.start),
                end: new Date(item.end),
              };
            })
          : [];
        res(events);
      } else {
        res([]);
      }
    });
  };

  const handleDelete = async (deletedId) => {
    const propertyEvents = grefs.bookings.current.find(
      (item) => item.id === selectedProperty
    );

    const events =
      propertyEvents && propertyEvents.bookings ? propertyEvents.bookings : [];

    const updatedBooking = events.filter((item) => item.event_id !== deletedId);

    const payload = grefs.bookings.current
      .filter((item) => item.id !== selectedProperty)
      .concat([
        {
          user: credentials.user,
          id: selectedProperty,
          bookings: updatedBooking,
        },
      ]);

    const ret = await editDynamoDBData(
      auth,
      "booking",
      payload.find((item) => item.id === selectedProperty)
    );
    console.log("ret", ret);

    grefs.bookings.current = payload;
    console.log("deletedId", deletedId);
    return new Promise((res, rej) => {
      res(deletedId);
    });
  };

  return reload ? null : selectedProperty && bookingsLoaded ? (
    <Scheduler
      height={400}
      week={{
        weekDays: [0, 1, 2, 3, 4, 5],
        weekStartOn: 6,
        startHour: 9,
        endHour: 18,
        step: 60,
        // cellRenderer?:(props) => JSX.Element,
        navigation: true,
        disableGoToDay: false,
      }}
      getRemoteEvents={fetchRemote}
      onDelete={handleDelete}
      customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
    />
  ) : null;
};
