import React, { useContext, useState } from "react";
import { StoreContext } from "../store/Store";
import { EditRow } from "./properties/EditRow";
import { PropertyTable } from "./properties/PropertyTable";

export const Properties = () => {
  const [store] = useContext(StoreContext);
  const { properties } = store;

  const [defaultWidth] = useState({
    address: 500,
    id: 400,
    colour:60
  });

  const parentStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };

  return properties ? (
    <div style={parentStyle}>
      <div style={{ width: "100%", marginLeft: 100 ,marginTop:40}}>
        <EditRow defaultWidth={defaultWidth} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginLeft: 100,
        }}
      >
        <PropertyTable defaultWidth={defaultWidth} />
      </div>
    </div>
  ) : null;
};
