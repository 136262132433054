import { useContext } from "react";
import { StoreContext } from "../store/Store";
import { actionTypes } from "../store/storeTypes";

const list = [
  "Properties",
  "Customers",
  "Bookings",
  "Availability",
  "Costing",
  "Invoicing",
];

export const TabButtons = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { selected } = store;

  const container = {
    display: "flex",
    gap: 20,
    fontSize: 12,
    fontWeight: 600,
    backgroundColor: "#ffffff",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    paddingLeft: 40,
    paddingRight: 40,
  };

  const button = {
    flex: 1,
    cursor: "pointer",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 12,
    paddingRight: 12,
    border: "1px solid black",
    textAlign: "center",
    borderRadius: 8,
  };

  const getBackground = (item) => {
    return "#ffffff";
  };
  const gettextColor = (item) => {
    return "black";
  };

  const onClick = (e, item) => {
    e.stopPropagation();
    dispatch({ type: actionTypes.SET_SELECTED, payload: item });
  };

  return (
    <div style={container}>
      {list.map((item, index) => (
        <div
          style={{
            ...button,
            color: selected === item ? "#ffffff" : gettextColor(item),
            backgroundColor: selected === item ? "brown" : getBackground(item),
          }}
          key={index}
          onClick={(e) => onClick(e, item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};
