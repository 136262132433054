import React, { useContext, useState } from "react";
import { StoreContext } from "../store/Store";
import { EditRow } from "./customers/EditRow";
import { CustomerTable } from "./customers/CustomerTable";

export const Customers = () => {
  const [store] = useContext(StoreContext);
  const { customers } = store;

  const [defaultWidth] = useState({
    firstName: 200,
    surname: 200,
    id: 300,
  });

  const parentStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };

  return customers ? (
    <div style={parentStyle}>
      <div style={{ width: "100%", marginLeft: 100, marginTop: 40 }}>
        <EditRow defaultWidth={defaultWidth} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginLeft: 100,
        }}
      >
        <CustomerTable defaultWidth={defaultWidth} />
      </div>
    </div>
  ) : null;
};
