import React, { useContext, useEffect } from "react";
import { StoreContext } from "../store/Store";
import { GlobalRefContext } from "../store/GlobalRefs";
import Auth from "../services/auth";
import { Properties } from "./Properties";
import { actionTypes } from "../store/storeTypes";
import { Customers } from "./Customers";
import { Bookings } from "./Bookings";
import { Availability } from "./Availability";
import { Costing } from "./Costing";
import { Invoicing } from "./Invoicing";
import { TabButtons } from "../components/TabButtons";
import { GetCredentials } from "./GetCredentials";
import { NewPassword } from "./NewPassword";
import { ForgotPassword } from "./ForgotPassword";
import { getDynamoDBData } from "../api/api";

const authServ = new Auth();

export const Home = () => {
  const [store, dispatch] = useContext(StoreContext);
  const {
    selected,
    auth,
    credentials,
    forgotPassword,
    loggedIn,
    requireNewPassword,
  } = store;

  const grefs = useContext(GlobalRefContext);

  useEffect(() => {
    if (authServ) {
      dispatch({ type: actionTypes.SET_AUTH, payload: authServ });
    }
  }, [dispatch]);

  useEffect(() => {
    if (credentials && auth) {
      const asyncfunction = async () => {
        const res = await auth.login(credentials.user, credentials.password);

        if (res.status === "success") {
          dispatch({
            type: actionTypes.SET_LOGGED_IN,
            payload: { status: true, message: "" },
          });
        } else if (res.status === "new-password-required") {
          dispatch({
            type: actionTypes.SET_REQUIRE_NEW_PASSWORD,
            payload: true,
          });
          console.log("new password required");
        } else {
          dispatch({
            type: actionTypes.SET_LOGGED_IN,
            payload: { status: false, message: res.errorMessage },
          });
        }
      };
      asyncfunction();
    }
  }, [auth, dispatch, credentials]);

  useEffect(() => {
    if (loggedIn.status) {
      const asyncFunction = async () => {
        const resProperties = await getDynamoDBData(
          auth,
          "properties",
          credentials.user
        );
        if (resProperties.statusCode === 200) {
          dispatch({
            type: actionTypes.SET_PROPERTIES,
            payload: resProperties.body,
          });
        }
        const resCustomers = await getDynamoDBData(
          auth,
          "customers",
          credentials.user
        );
        if (resCustomers.statusCode === 200) {
          dispatch({
            type: actionTypes.SET_CUSTOMERS,
            payload: resCustomers.body,
          });
        }
        const resBookings = await getDynamoDBData(
          auth,
          "bookings",
          credentials.user
        );
        if (resBookings.statusCode === 200) {
          grefs.bookings.current = resBookings.body;
          dispatch({
            type: actionTypes.SET_BOOKINGS_LOADED,
            payload: true,
          });
        }
        console.log("resBookings", resBookings);
      };
      asyncFunction();
    }
  }, [auth, dispatch, credentials, grefs, loggedIn]);

  return loggedIn.status && auth ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TabButtons />
      <div
        style={{
          width: "100%",
          flexGrow: 1,
          flexStretch: 0,
          flexBasis: "auto",
        }}
      >
        {selected === "Properties" && <Properties />}
        {selected === "Customers" && <Customers />}
        {selected === "Bookings" && <Bookings />}
        {selected === "Availability" && <Availability />}
        {selected === "Costing" && <Costing />}
        {selected === "Invoicing" && <Invoicing />}
      </div>
    </div>
  ) : (
    <>
      {requireNewPassword ? (
        <NewPassword />
      ) : forgotPassword.status ? (
        <ForgotPassword />
      ) : (
        <GetCredentials />
      )}
    </>
  );
};
